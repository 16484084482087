.article {
  top: -30px;
  position: relative;
  z-index: 99;
  background: white;
  /* background: rgb(245, 237, 221); */
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  box-shadow: 0 -15px 20px -5px rgba(0, 0, 0, 0.1),
    -15px 0 5px -5px rgba(0, 0, 0, 0.1), 15px 0 5px -5px rgba(0, 0, 0, 0.1);
}

.video-responsive {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
  position: relative;
}

.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
